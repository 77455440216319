type GetAmountOfListedItemsArgumentsTypes = {
  activeListPage: number;
  limitOnPage: number;
  totalItems: number;
};

type GetNewActiveListPageArgumentsTypes = {
  prevActiveListPage: number;
  prevLimitOnPage: number;
  currentLimitOnPage: number;
  totalItems: number;
};

export const getAmountOfListedItems = ({
  activeListPage,
  limitOnPage,
  totalItems,
}: GetAmountOfListedItemsArgumentsTypes): number =>
  (activeListPage + 1) * limitOnPage <= totalItems
    ? (activeListPage + 1) * limitOnPage
    : totalItems;

export const getNewActiveListPage = ({
  prevActiveListPage,
  prevLimitOnPage,
  currentLimitOnPage,
  totalItems,
}: GetNewActiveListPageArgumentsTypes): number => {
  if (prevActiveListPage === 0) return 0;

  let newActiveListPage = 0;
  const amountOfListedItems = getAmountOfListedItems({
    activeListPage: prevActiveListPage,
    limitOnPage: prevLimitOnPage,
    totalItems,
  });

  while ((newActiveListPage + 1) * currentLimitOnPage < amountOfListedItems) {
    newActiveListPage += 1;
  }

  return newActiveListPage;
};

export const countNumberOfElements = (
  activePage: any,
  pagesQuantity: any,
  itemsRendered: any,
  pageLimit: any
) => {
  if (pagesQuantity === 1 || activePage === 0) {
    return itemsRendered.length;
  }

  if (activePage + 1 === pagesQuantity) {
    return pageLimit * activePage + itemsRendered.length;
  }

  return (activePage + 1) * pageLimit;
};
