import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import sortBy from 'lodash.sortby';

import { paginateItems } from 'utils/helpers';

import { IHookAlgoliaResponse } from './model';
import { getNewActiveListPage, countNumberOfElements } from './helpers';

const useAlgoliaResponse = <T extends { link: string } | { url: string }>(
  limitOnPage: number,
  defaultItems: T[],
  manuallySelected: boolean,
  links?: string[],
  filters?: AppFilters.IChosenFilterItemData[]
): IHookAlgoliaResponse<T> => {
  const prevLimitOnPage = useRef<number>(0);
  const [isAlgoliaFiltersUsed, setAlgoliaFiltersUsed] = useState<boolean>(false);
  const [isHitsResponseActivated, setHitsResponseActivated] = useState<boolean>(false);
  const [activeListPage, setActiveListPage] = useState<number>(0);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [sortedItems, setSortedItems] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [countFilteredItems, setCountFilteredItems] = useState<number>(0);

  const allItems =
    (isAlgoliaFiltersUsed && isHitsResponseActivated) || !defaultItems.length
      ? filteredItems
      : defaultItems;
  const pageCount = useMemo(() => Math.ceil(allItems.length / limitOnPage), [
    allItems.length,
    limitOnPage,
  ]);

  useEffect(() => {
    const itemsRendered = paginateItems(sortedItems, limitOnPage, activeListPage);
    const sumOfShownElements = countNumberOfElements(
      activeListPage,
      pageCount,
      itemsRendered,
      limitOnPage
    );
    setCountFilteredItems(sumOfShownElements);
  }, [filters, activeListPage, sortedItems]);

  useEffect(() => {
    setFilteredItems(defaultItems);
    prevLimitOnPage.current = limitOnPage;
  }, []);

  useEffect(() => {
    if (prevLimitOnPage.current === limitOnPage) return;

    const newActiveListPage = getNewActiveListPage({
      prevActiveListPage: activeListPage,
      prevLimitOnPage: prevLimitOnPage.current,
      currentLimitOnPage: limitOnPage,
      totalItems: allItems.length,
    });

    setActiveListPage(newActiveListPage);

    prevLimitOnPage.current = limitOnPage;
  }, [limitOnPage]);

  useEffect(() => {
    if (manuallySelected && links?.length) {
      if (filters?.length) {
        const preferred = filters?.reduce((acc, { itemId }) => {
          allItems?.forEach((item) => {
            if (item?.preferred?.[0].id === itemId) {
              acc.push(item);
            }
          });

          return acc;
        }, [] as T[]);
        const sortedPreferred = sortBy(preferred, (item) => links?.indexOf(item.link));
        const filteredResults = allItems.filter((item) =>
          filters?.every((filter) => filter.itemId !== item?.preferred?.[0].id)
        );
        const sortedRest = sortBy(filteredResults, (item) => links?.indexOf(item.link));
        setSortedItems([...sortedPreferred, ...sortedRest]);
      } else {
        const sortedResults = sortBy(allItems, (item) => links?.indexOf(item.link));
        setSortedItems(sortedResults);
      }
    } else {
      setSortedItems(allItems);
    }
  }, [allItems, filters]);

  const itemsToRender = useMemo(() => {
    if (sortedItems.length) {
      setLoading(false);

      return paginateItems(sortedItems, limitOnPage, activeListPage);
    }

    return [];
  }, [activeListPage, sortedItems, limitOnPage]);

  const handleActiveListPage = useCallback((listPage: number) => {
    setActiveListPage(listPage);
  }, []);

  const saveAlgoliaHitsResponse = useCallback((items: any[]) => {
    setFilteredItems(items);
  }, []);

  const handleAlgoliaFiltersUsed = useCallback((value: boolean) => {
    setAlgoliaFiltersUsed(value);
  }, []);

  const handleHitsResponseActivated = useCallback((value: boolean) => {
    setHitsResponseActivated(value);
  }, []);

  return {
    itemsToRender,
    activeListPage,
    handleActiveListPage,
    pageCount,
    saveAlgoliaHitsResponse,
    handleAlgoliaFiltersUsed,
    handleHitsResponseActivated,
    isHitsResponseActivated,
    itemsTotal: allItems.length,
    loading,
    countFilteredItems,
  };
};

export default useAlgoliaResponse;
