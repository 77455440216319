import React, { FC } from 'react';

import { IPropsPaginationItem } from './model';

const PaginationItem: FC<IPropsPaginationItem> = ({
  handlePagingClick,
  num,
  active,
  isNumerationStartFromZero = true,
}) => {
  const numberToDisplay = isNumerationStartFromZero ? num + 1 : num;

  return (
    <li
      className={active === num ? 'active' : ''}
      data-testid={`paginationItem ${numberToDisplay}`}
    >
      <button
        type="button"
        data-testid={`paginationButton ${numberToDisplay}`}
        aria-label={`paginationItem ${numberToDisplay}`}
        onClick={handlePagingClick}
      >
        {numberToDisplay}
      </button>
    </li>
  );
};

export default PaginationItem;
