import React, { FC, useCallback, useEffect, useMemo } from 'react';

import {
  generateLocationQueryString,
  getLocationQueryStringParam,
  getRemovedParamFromLocationQueryString,
  scrollToTarget,
  setUrl,
} from 'utils/browser';

import PaginationItem from 'components/Pagination/PaginationItem';

import './Pagination.scss';
import { IPropsPagination } from './model';

const PAGE_URL_PARAM = 'page';

const Pagination: FC<IPropsPagination> = ({
  handleActiveListPage,
  pageCount,
  active,
  scrollTargetId,
}) => {
  const numbers = useMemo(() => [...Array(pageCount).keys()], [pageCount]);
  const getCurrentPageFromUrl = useMemo(
    () => () => parseInt(getLocationQueryStringParam(PAGE_URL_PARAM, 'string'), 10) || 0,
    []
  );

  const handleActiveListPageOnLoad = useCallback(() => {
    const currentPage = getCurrentPageFromUrl();

    if (!currentPage) {
      return;
    }

    handleActiveListPage(currentPage);
  }, []);

  useEffect(() => {
    handleActiveListPageOnLoad();
  }, []);

  const setPaginationUrlParams = useCallback((currentPage: number): void => {
    let url: string | null = '';
    if (currentPage) {
      url = generateLocationQueryString(PAGE_URL_PARAM, String(currentPage));
    } else {
      url = getRemovedParamFromLocationQueryString(PAGE_URL_PARAM);
    }

    setUrl(url || '');
  }, []);

  useEffect(() => {
    if (active !== getCurrentPageFromUrl()) {
      setPaginationUrlParams(active);
    }
  }, [active]);

  const handleChangePage = useCallback(
    (currentPage: number) => () => {
      handleActiveListPage(currentPage);
      setPaginationUrlParams(currentPage);
      scrollToTarget(scrollTargetId);
    },
    []
  );

  return (
    <ul data-test="Pagination" className="nf-pagination">
      {numbers.map((n) => (
        <PaginationItem key={n} handlePagingClick={handleChangePage(n)} num={n} active={active} />
      ))}
    </ul>
  );
};
export default Pagination;
