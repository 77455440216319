import React, { FC, ReactElement, useCallback, useEffect, useMemo } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-dom';

import { parseProductQuizData } from 'utils/parsers';
import {
  IAlgoliaSearchStateData,
  HitsPerPage,
  getSavedSelectionDataToSet,
} from 'utils/algoliaFilters';

import AlgoliaCustomHits from 'components/AlgoliaFilters/CustomHits';
import ConnectedCustomRefinementList from 'components/AlgoliaQuizResult/CustomRefinementList';

import { IPropsAlgoliaQuizResult } from './models';
import Helpers from './helpers';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY as string
);

const AlgoliaQuizResult: FC<IPropsAlgoliaQuizResult> = ({
  productQuizData,
  indexName,
  saveAlgoliaHitsResponse,
  handleAlgoliaFiltersUsed,
  handleHitsResponseActivated,
  isHitsResponseActivated,
  lang,
  searchState,
  setSearchState,
}): ReactElement | null => {
  const questions = useMemo(() => parseProductQuizData(productQuizData), [productQuizData]);

  const handleActiveFiltersIdsOnLoad = useCallback(() => {
    const newSearchState = getSavedSelectionDataToSet();
    if (!newSearchState) {
      return;
    }
    handleAlgoliaFiltersUsed(true);
    setSearchState(newSearchState);
  }, []);

  useEffect(() => {
    handleActiveFiltersIdsOnLoad();
  }, []);

  const checkAlgoliaFiltersUsage = useCallback((newSearchState: IAlgoliaSearchStateData) => {
    if (!newSearchState?.refinementList) {
      handleAlgoliaFiltersUsed(false);

      return;
    }

    if (Object.keys(newSearchState.refinementList).length) {
      handleAlgoliaFiltersUsed(true);

      return;
    }

    handleAlgoliaFiltersUsed(false);
  }, []);

  const handleOnSearchStateChange = useCallback((newSearchState: IAlgoliaSearchStateData) => {
    checkAlgoliaFiltersUsage(newSearchState);
    setSearchState(newSearchState);
  }, []);

  return searchClient ? (
    <div data-test="AlgoliaQuizResult">
      <InstantSearch
        indexName={indexName}
        searchClient={searchClient}
        refresh
        stalledSearchDelay={500}
        searchState={searchState}
        onSearchStateChange={handleOnSearchStateChange}
      >
        <Configure
          filters={Helpers.getDefaultFiltersParams(lang)}
          hitsPerPage={HitsPerPage}
          analytics={false}
          distinct
          maxValuesPerFacet={HitsPerPage}
        />

        {questions?.map(({ attributeForFaceting }) => (
          <ConnectedCustomRefinementList
            key={attributeForFaceting}
            attribute={attributeForFaceting}
          />
        ))}

        <AlgoliaCustomHits
          saveAlgoliaHitsResponse={saveAlgoliaHitsResponse}
          handleHitsResponseActivated={handleHitsResponseActivated}
          isHitsResponseActivated={isHitsResponseActivated}
        />
      </InstantSearch>
    </div>
  ) : null;
};

export default AlgoliaQuizResult;
