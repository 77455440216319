import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from 'components/Layout';

import './QuizResultPage.scss';
import AlgoliaQuizResult from 'components/AlgoliaQuizResult';
import Pagination from 'components/Pagination';
import { DefaultSearchState, IAlgoliaSearchStateData } from 'utils/algoliaFilters';
import ProductCardList from 'components/ProductCardList';
import useOnViewport from 'hooks/useOnViewport';
import useAlgoliaResponse from 'hooks/useAlgoliaResponse';
import useScreenRecognition from 'hooks/useScreenRecognition';
import BreadCrumbs from 'components/BreadCrumbs';
import ProductsFinder from 'components/ProductsFinder';
import { Container } from 'react-bootstrap';
import { gtmService } from 'services/gtmService';

interface IQuizResultPageProps extends PageProps {
  pageContext: { breadcrumbs: UmbracoTypes.IBreadCrumb[] };
  data: any;
}

const COMPONENT_ID = 'productQuizListFilter';
const desktopLimit = 6;
const mobileLimit = 3;

const QuizResultPage: FC<IQuizResultPageProps> = ({
  pageContext: { breadcrumbs },
  data: {
    quizResult: {
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      ogImage,
      lang,
      title,
      startQuiz,
      pageName,
    },
    header,
    footer,
    siteSettings,
    covidBanner,
    productQuiz,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    productsLinks,
    searchPath,
    brandSettings,
    defaultProducts,
    legalPopup,
    pageLangs: { nodes: langs },
    carouselControls,
  },
}) => {
  const [searchState, setSearchState] = useState<IAlgoliaSearchStateData>(DefaultSearchState);
  const { isMobile, isMiddleTablet } = useScreenRecognition();
  const isSmallDevice = isMobile || isMiddleTablet;
  const productsCardListRef = useRef<HTMLDivElement>(null);
  const isProductsCardListInViewport = useOnViewport(productsCardListRef, '-75px');
  const limit = isSmallDevice ? mobileLimit : desktopLimit;

  const itemParsedData = {
    startQuiz: startQuiz?.[0]?.properties,
    productQuizData: productQuiz,
    lang,
    ariaLabelPrev: carouselControls.ariaLabelPrev,
    ariaLabelNext: carouselControls.ariaLabelNext,
  };

  const {
    itemsToRender,
    activeListPage,
    handleActiveListPage,
    pageCount,
    saveAlgoliaHitsResponse,
    handleAlgoliaFiltersUsed,
    handleHitsResponseActivated,
    isHitsResponseActivated,
    loading,
    itemsTotal,
    countFilteredItems,
  } = useAlgoliaResponse<ProductCard.IProductCard>(
    limit,
    defaultProducts.nodes,
    false,
    productsLinks
  );
  const [products, setProducts] = useState<ProductCard.IProductCard[]>(itemsToRender);
  const [isOpenFinder, setIsOpenFinder] = useState(false);
  const callbackHandleQuizVisibility = useCallback((isOpen: boolean) => {
    if (isOpen) {
      setIsOpenFinder(true);
    }
  }, []);

  const handleCompleteProductsFinder = useCallback(() => {
    setIsOpenFinder(false);
  }, []);

  useEffect(() => {
    let timeoutId;
    if (!isOpenFinder && !loading && itemsToRender.length) {
      setProducts(itemsToRender);
      timeoutId = gtmService.emitProductListingView(pageName, itemsToRender);
    }

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [loading, itemsToRender, isOpenFinder]);

  return (
    <Layout
      seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs, ogImage }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      legalPopup={legalPopup}
      langSettings={{
        currentLang: lang,
        langs,
      }}
    >
      <div className="quiz-result-page">
        <Container fluid>
          {breadcrumbs?.length ? <BreadCrumbs data={breadcrumbs} activeLastItem /> : null}
          <h2 className="quiz-result-page__title">{title}</h2>
        </Container>
        <AlgoliaQuizResult
          indexName={`${process.env.GATSBY_ALGOLIA_INDEX_PREFIX}-productQuiz`}
          productQuizData={productQuiz}
          saveAlgoliaHitsResponse={saveAlgoliaHitsResponse}
          handleAlgoliaFiltersUsed={handleAlgoliaFiltersUsed}
          handleHitsResponseActivated={handleHitsResponseActivated}
          isHitsResponseActivated={isHitsResponseActivated}
          lang={lang}
          searchState={searchState}
          setSearchState={setSearchState}
        />
        <div ref={productsCardListRef} className="products-card-list-wrapper">
          <ProductCardList
            products={products}
            shopBtnShow={false}
            isShowLabels={false}
            isArticlesCounter={false}
            sorryMessage=""
            isProductsCardListInViewport={isProductsCardListInViewport}
            isSmallDevice={isSmallDevice}
            countFilteredItems={countFilteredItems}
            itemsTotal={itemsTotal}
          />
          {itemsTotal > limit ? (
            <Pagination
              handleActiveListPage={handleActiveListPage}
              pageCount={pageCount}
              active={activeListPage}
              scrollTargetId={COMPONENT_ID}
            />
          ) : null}
        </div>
        <ProductsFinder
          callbackHandleQuizVisibility={callbackHandleQuizVisibility}
          handleParentAlgoliaFiltersUsed={handleAlgoliaFiltersUsed}
          handleCompleteProductsFinder={handleCompleteProductsFinder}
          setParentSearchState={setSearchState}
          {...itemParsedData}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!, $link: String!, $pageIdRegex: String, $productsLinks: [String]) {
    quizResult(link: { eq: $link }, lang: { eq: $lang }) {
      link
      lang
      ...FragmentSeo
      title
      pageName
      productsLinks
      startQuiz {
        properties {
          ...FragmentStartQuizSection
        }
      }
    }
    pageLangs: allQuizResult(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    defaultProducts: allUmbracoProduct(
      filter: { link: { in: $productsLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    productQuiz {
      ...FragmentProductQuiz
    }
    carouselControls: siteSettings(lang: { eq: $lang }) {
      ariaLabelNext
      ariaLabelPrev
    }
    ...FragmentCommonCompositions
  }
`;

export default QuizResultPage;
